import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import css from "./style.module.scss";
import {
  Avatar,
  Logo,
  Container,
  QuotesForm,
  FileUnknownOutlined,
} from "../../components";

import AboutPopup from "../../components/quotes-panel/components/profile/decision-support/popups/about-popup";

import _ from "lodash";
import axios from "axios";

type SelectedAgent = {
  name: string;
  profile_picture_link: string;
  title: string;
  bio: string;
  phone: string;
  business_phone: string;
};

const IndexPage = (props) => {
  const [selectedAgent, setSelectedAgent] = useState({} as SelectedAgent);

  const data = useStaticQuery(graphql`
    query assets2 {
      site {
        siteMetadata {
          baseUrl
          agent {
            id
            assets {
              image
            }
            baseUrl
            imageSrc
            name
            phone
            whatsapp
            email
            website
            title
            calendly
            bio
          }
        }
      }
    }
  `);
  const [aboutPopupVisible, setAboutPopupVisible] = useState(false);

  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);

  useEffect(() => {
    if (data.site.siteMetadata.agent.id) {
      (async () => {
        const response = await axios.get(
          `https://api.qa.myaspirequotes.com/v1/platforms/${data.site.siteMetadata.agent.id}`,
          {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbnRlZ3JhdGlvbkBhcG9sbG90ZS5jaCJ9.BeAF6gKEWzqj2ro1-IxvCA9PrLBM4TKlA1QnjAT_Iy0",
            },
          }
        );

        console.log(response.data);

        setSelectedAgent({
          ...response.data,
          bio: response.data.bio === "<p></p>" ? null : response.data.bio,
        });
      })();
    }
  }, [data?.site?.siteMetadata?.agent]);

  return (
    <Container className={css.homeContainer}>
      <AboutPopup
        data={selectedAgent.bio}
        visible={aboutPopupVisible}
        onClose={closeAboutPopup}
      />
      {/* <Helmet>
          <meta viewport="width=device-width, initial-scale=1, minimum-scale=1,maximum-scale=1, shrink-to-fit=no"/>
        </Helmet> */}
      {/* //<SEO title="Home" /> */}
      <Container className={css.agentDataContainer}>
        <Container className={css.agentDataContainerContent}>
          <Container className={css.logoContainer}>
            <Container className={css.logo}>
              <img src="/maq_logo_white.svg" alt="MyAspireQuotes logo" />
            </Container>
          </Container>
          <Container className={css.agentData}>
            <Container className={css.avatarContainer}>
              <Avatar
                className={css.avatar}
                link=""
                round="true"
                src={selectedAgent?.profile_picture_link}
              />
            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container className={css.agentName}>
                {selectedAgent.name}
              </Container>
              {selectedAgent.title && (
                <Container className={css.agentTitle}>
                  {selectedAgent.title}
                </Container>
              )}
              {selectedAgent.bio && (
                <Container
                  onPress={() => {
                    openAboutPopup();
                  }}
                  className={css.bioButton}
                >
                  {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio
                </Container>
              )}
              <Container>
                {selectedAgent.phone && (
                  <Container className={css.agentInfo}>
                    <svg className={css.phone}>
                      <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                    </svg>

                    <span>{selectedAgent.phone}</span>
                  </Container>
                )}
                {selectedAgent.business_phone && (
                  <Container className={css.agentInfo}>
                    <svg className={css.phone}>
                      <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                    </svg>

                    <span>{selectedAgent.business_phone}</span>
                  </Container>
                )}

                {data?.site?.siteMetadata?.agent?.calendly && (
                  <a
                    target="_blank"
                    href={data?.site?.siteMetadata?.agent?.calendly}
                  >
                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#schedule-call-icon" />
                      </svg>
                      <span>Schedule a Call</span>
                    </Container>
                  </a>
                )}
                {false && (
                  <a
                    target="_blank"
                    href={"https://m.me/ApolloInsuranceAdvisors"}
                  >
                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#facebook-icon" />
                      </svg>
                      <span>Send Message</span>
                    </Container>
                  </a>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={css.agentDataContainerMobile}>
        <Container className={css.mobileBg}>
          <Container className={css.mobileAgentData}>
            <Container className={css.mobileLogoContainer}>
              <Logo />
            </Container>

            <Container className={css.avatarContainer}>
              <Avatar
                className={css.avatar}
                link=""
                round="true"
                src={data?.site?.siteMetadata?.agent?.imageSrc}
              />
            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container>
                <Container className={css.agentName}>
                  {data.site.siteMetadata.agent.name}
                </Container>
                <Container className={css.agentTitle}>
                  {data.site.siteMetadata.agent.title}
                </Container>

                <Container className={css.agentInfoContainer}>
                  <Container className={css.agentInfo}>
                    <svg className={css.phone}>
                      <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                    </svg>

                    <span>{data.site.siteMetadata.agent.phone}</span>
                  </Container>
                  {data?.site?.siteMetadata?.agent?.calendly && (
                    <a
                      target="_blank"
                      href={data?.site?.siteMetadata?.agent?.calendly}
                    >
                      <Container className={css.agentInfo}>
                        <svg>
                          <use xlinkHref="/sprite.svg#schedule-call-icon" />
                        </svg>
                        <span>Schedule a Call</span>
                      </Container>
                    </a>
                  )}
                  {false && (
                    <a
                      target="_blank"
                      href={"https://m.me/ApolloInsuranceAdvisors"}
                    >
                      <Container className={css.agentInfo}>
                        <svg>
                          <use xlinkHref="/sprite.svg#facebook-icon" />
                        </svg>
                        <span>Send Message</span>
                      </Container>
                    </a>
                  )}
                </Container>
              </Container>
              <Container
                onPress={() => {
                  openAboutPopup();
                }}
                className={css.bioButton}
              >
                {/* {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio */}
                View Bio
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={css.mainContainer}>
        <Container className={css.mainContainerInner}>
          <Container className={css.headingsContainer}>
            <h1 className={`h1 ${css.healthInsurance}`}> Health insurance</h1>
            <h1 className={`h1 ${css.quotes}`}> Quotes and</h1>
            <h1 className={`h1 ${css.plans}`}> Plans</h1>
          </Container>
          <Container className={`${css.contentContainer}`}>
            <QuotesForm agentMetadata={data?.site?.siteMetadata?.agent} />

            <Container className={`${css.disclaimer}`}>
              <span>Disclaimer</span>
              These quotes are only estimates. Your actual premium, deductible,
              and coverage may be different. The quotes offered on this platform
              are not a promise of insurance, and your eligibility can only be
              determined by a qualified agent. Only your qualified agent can
              determine your insurability, plan rates, and health coverage. All
              quotes are subject to change.
            </Container>

            {/*<Container className={ `hidden-on-mobile ${ css.eManualContainer}`}>*/}
            {/*  <FileUnknownOutlined /> <a target="_blank" href="https://docs.google.com/presentation/d/16pLZNe3V6zv5WoQqqlhS2mUNqOUZjHLHYjjmVeSmtio/present?slide=id.p">Access the Apollo Quotes E-manual </a>*/}
            {/*</Container>*/}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default IndexPage;
